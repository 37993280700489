import { db } from '../config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const migrateHabitsCollections = async (userId: string) => {
    try {
        // Check if habits collection exists for user
        const habitsQuery = query(
            collection(db, 'habits'),
            where('userId', '==', userId)
        );
        
        const habitsSnapshot = await getDocs(habitsQuery);
        
        // If no habits exist, collection will be created when first habit is added
        console.log(`Found ${habitsSnapshot.size} existing habits for user`);
        
        return true;
    } catch (error) {
        console.error('Error in habits migration:', error);
        return false;
    }
};
