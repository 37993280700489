import React, { createContext, useState, useMemo, ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { alpha } from '@mui/material/styles';

export const ColorModeContext = createContext({ 
  toggleColorMode: () => {},
  mode: 'dark' as 'light' | 'dark' | 'blue' | 'purple',
  setColorMode: (mode: 'light' | 'dark' | 'blue' | 'purple') => {}
});

interface ColorModeProviderProps {
  children: ReactNode;
}

export const ColorModeProvider: React.FC<ColorModeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState<'light' | 'dark' | 'blue' | 'purple'>('dark');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          if (prevMode === 'light') return 'dark';
          if (prevMode === 'dark') return 'light';
          if (prevMode === 'blue') return 'purple';
          return 'dark'; // If it's purple, go back to dark
        });
      },
      setColorMode: (newMode: 'light' | 'dark' | 'blue' | 'purple') => {
        setMode(newMode);
      },
      mode,
    }),
    [mode],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode === 'blue' || mode === 'purple' ? 'dark' : mode, // Blue and purple themes are based on dark mode
          ...(mode === 'dark' ? {
            // Dark mode colors (unchanged)
            primary: {
              main: '#6C5DD3',
              dark: '#4A3FB9',
            },
            secondary: {
              main: '#FF8B49',
              dark: '#E66C29',
            },
            success: {
              main: '#3DD598',
              dark: '#2AAB76',
            },
            error: {
              main: '#FF5C5C',
              dark: '#E63939',
            },
            background: {
              default: '#1a1b1e',
              paper: '#2a2b2e',
            },
            text: {
              primary: '#ffffff',
              secondary: '#9da3af',
            },
          } : mode === 'blue' ? {
            // Blue dark theme
            primary: {
              main: '#3282F6',
              dark: '#1A64D9',
            },
            secondary: {
              main: '#4CB5FF',
              dark: '#3097E6',
            },
            success: {
              main: '#3DD598',
              dark: '#2AAB76',
            },
            error: {
              main: '#FF5C5C',
              dark: '#E63939',
            },
            background: {
              default: '#0A1930',
              paper: '#162A45',
            },
            text: {
              primary: '#ffffff',
              secondary: '#a8c2e2',
            },
            // Chart colors for blue theme
            chart: {
              success: '#3DD5F3',
              error: '#FF5C5C',
              primary: '#4CB5FF',
              secondary: '#6EB9FF',
            },
          } : mode === 'purple' ? {
            // Elegant subdued purple theme
            primary: {
              main: '#7A6EAA',  // Muted lavender purple
              dark: '#5D4D88',  // Deeper muted purple
            },
            secondary: {
              main: '#9C94BC',  // Soft lavender gray
              dark: '#7E6E9A',  // Muted dark lavender
            },
            success: {
              main: '#3DD598',
              dark: '#2AAB76',
            },
            error: {
              main: '#FF5C5C',
              dark: '#E63939',
            },
            background: {
              default: '#241C38',  // Deep but muted purple-blue
              paper: '#2F2845',    // Slightly lighter muted purple
            },
            text: {
              primary: '#ffffff',
              secondary: '#BCAFD9',  // Softer lavender text
            },
            // Chart colors for purple theme
            chart: {
              success: '#3DD5F3',
              error: '#FF5C5C',
              primary: '#9C94BC',  // Matching the secondary main
              secondary: '#BDB7D5', // Very light lavender
            },
          } : {
            // Light mode colors (improved)
            primary: {
              main: '#6C5DD3',
              light: '#8677E0',
              dark: '#4A3FB9',
              contrastText: '#FFFFFF',
            },
            secondary: {
              main: '#FF8B49',
              light: '#FFA676',
              dark: '#E66C29',
              contrastText: '#FFFFFF',
            },
            success: {
              main: '#3DD598',
              light: '#65DFB0',
              dark: '#2AAB76',
              contrastText: '#FFFFFF',
            },
            error: {
              main: '#FF5C5C',
              light: '#FF8080',
              dark: '#E63939',
              contrastText: '#FFFFFF',
            },
            background: {
              default: '#F8F9FE',
              paper: '#FFFFFF',
            },
            text: {
              primary: '#2B3674',
              secondary: '#707EAE',
            },
            grey: {
              50: '#F8F9FE',
              100: '#F4F7FE',
              200: '#E9EDF7',
              300: '#E1E6F0',
              400: '#D8DEE9',
              500: '#A3AED0',
            },
          }),
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundImage: 'none',
                ...(mode === 'light' && {
                  boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.04)',
                  borderRadius: 16,
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                }),
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 16,
                ...(mode === 'light' && {
                  boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.04)',
                  background: '#FFFFFF',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                }),
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 12,
                textTransform: 'none',
                fontWeight: 600,
                ...(mode === 'light' && {
                  boxShadow: 'none',
                }),
              },
              contained: {
                ...(mode === 'light' && {
                  boxShadow: '0 2px 8px 0 rgba(108, 93, 211, 0.15)',
                  '&:hover': {
                    boxShadow: '0 4px 12px 0 rgba(108, 93, 211, 0.25)',
                  },
                }),
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                ...(mode === 'light' && {
                  backgroundColor: '#FFFFFF',
                  borderRight: '1px solid rgba(0, 0, 0, 0.05)',
                }),
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                ...(mode === 'light' && {
                  borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                }),
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                ...(mode === 'light' && {
                  backgroundColor: '#FFFFFF',
                }),
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                ...(mode === 'light' && {
                  '&:hover': {
                    backgroundColor: alpha('#6C5DD3', 0.04),
                  },
                  '&.Mui-selected': {
                    backgroundColor: alpha('#6C5DD3', 0.08),
                    '&:hover': {
                      backgroundColor: alpha('#6C5DD3', 0.12),
                    },
                  },
                }),
              },
            },
          },
          MuiListItem: {
            styleOverrides: {
              root: {
                ...(mode === 'light' && {
                  '&:hover': {
                    backgroundColor: alpha('#6C5DD3', 0.04),
                  },
                  '&.Mui-selected': {
                    backgroundColor: alpha('#6C5DD3', 0.08),
                    '&:hover': {
                      backgroundColor: alpha('#6C5DD3', 0.12),
                    },
                  },
                }),
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                ...(mode === 'light' && {
                  backgroundColor: alpha('#6C5DD3', 0.08),
                  '&:hover': {
                    backgroundColor: alpha('#6C5DD3', 0.12),
                  },
                }),
              },
            },
          },
        },
        shape: {
          borderRadius: 12,
        },
        typography: {
          fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
          h1: {
            fontSize: '2.5rem',
            fontWeight: 600,
            ...(mode === 'light' && {
              color: '#2B3674',
            }),
          },
          h2: {
            fontSize: '2rem',
            fontWeight: 600,
            ...(mode === 'light' && {
              color: '#2B3674',
            }),
          },
          h3: {
            fontSize: '1.75rem',
            fontWeight: 600,
            ...(mode === 'light' && {
              color: '#2B3674',
            }),
          },
          h4: {
            fontSize: '1.5rem',
            fontWeight: 600,
            ...(mode === 'light' && {
              color: '#2B3674',
            }),
          },
          h5: {
            fontSize: '1.25rem',
            fontWeight: 600,
            ...(mode === 'light' && {
              color: '#2B3674',
            }),
          },
          h6: {
            fontSize: '1rem',
            fontWeight: 600,
            ...(mode === 'light' && {
              color: '#2B3674',
            }),
          },
          subtitle1: {
            ...(mode === 'light' && {
              color: '#707EAE',
            }),
          },
          subtitle2: {
            ...(mode === 'light' && {
              color: '#707EAE',
            }),
          },
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
