/**
 * Utility to filter unwanted console messages in production
 * This specifically targets browser warnings that can't be removed with babel-plugin-transform-remove-console
 */

const setupConsoleFilter = (): void => {
  if (process.env.NODE_ENV === 'production') {
    // Store original console methods
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;
    const originalConsoleLog = console.log;

    // Filter patterns to block
    const blockedPatterns = [
      'Chrome is moving towards a new experience',
      'It looks like Stripe.js was loaded more than one time',
      'Failed to execute \'postMessage\'',
      'Cross-origin-opener-Policy policy would block',
      'trade-stats.com',
      'js.stripe.com'
    ];
    
    // Override console methods to filter unwanted messages
    console.error = function(...args: any[]) {
      const message = String(args[0] || '');
      if (!blockedPatterns.some(pattern => message.includes(pattern))) {
        originalConsoleError.apply(console, args);
      }
    };

    console.warn = function(...args: any[]) {
      const message = String(args[0] || '');
      if (!blockedPatterns.some(pattern => message.includes(pattern))) {
        originalConsoleWarn.apply(console, args);
      }
    };

    console.log = function(...args: any[]) {
      const message = String(args[0] || '');
      if (!blockedPatterns.some(pattern => message.includes(pattern))) {
        originalConsoleLog.apply(console, args);
      }
    };
  }
};

export default setupConsoleFilter;
