import { Trade } from '../types/trade';
import { Account } from '../types/account';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Initialize dayjs plugins
dayjs.extend(customParseFormat);

// Encryption key should be stored securely - in a real app, consider environment variables
const ENCRYPTION_KEY = 'braindumper-mt5-secure-key'; 

export interface MT5Trade {
  ticket: number;
  symbol: string;
  type: number;
  volume: number;
  price_open: number;
  price_close: number;
  time_open: string;
  time_close: string;
  profit: number;
  commission: number;
  swap: number;
  comment: string;
  is_open: boolean;
  sl: number;
  tp: number;
}

export class Mt5Service {
  private apiBaseUrl: string = 'http://localhost:5000/api';
  
  constructor() {
    // Can be initialized with a different base URL if needed
  }

  /**
   * Encrypt sensitive data like passwords
   */
  encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, ENCRYPTION_KEY).toString();
  }

  /**
   * Decrypt sensitive data
   */
  decryptData(encryptedData: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  /**
   * Test connection to MT5
   */
  async testConnection(login: number, password: string, server: string): Promise<boolean> {
    try {
      const response = await fetch(`${this.apiBaseUrl}/test-connection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login,
          password,
          server
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to connect to MT5');
      }

      const data = await response.json();
      return data.success;
    } catch (error) {
      console.error('Error testing MT5 connection:', error);
      return false;
    }
  }

  /**
   * Get trades from MT5 account
   */
  async getTrades(account: Account, startDate?: Date, endDate?: Date): Promise<MT5Trade[]> {
    if (!account.mt5Connection?.enabled || 
        !account.mt5Connection.login || 
        !account.mt5Connection.password || 
        !account.mt5Connection.server) {
      throw new Error('MT5 connection not properly configured');
    }

    try {
      // Decrypt password
      const decryptedPassword = this.decryptData(account.mt5Connection.password);
      
      const response = await fetch(`${this.apiBaseUrl}/trades`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: account.mt5Connection.login,
          password: decryptedPassword,
          server: account.mt5Connection.server,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString()
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get trades from MT5');
      }

      const data = await response.json();
      return data.trades;
    } catch (error) {
      console.error('Error getting MT5 trades:', error);
      throw error;
    }
  }

  /**
   * Convert MT5 trades to BrainDumper format
   */
  private convertMT5TradesToBrainDumperFormat(mt5Trades: any[], accountId: string): Trade[] {
    return mt5Trades.map(mt5Trade => {
      // Generiere eine sichere, zufällige ID für den Trade
      const tradeId = Date.now().toString() + '-' + Math.random().toString(36).substring(2, 15);
      
      // Ermittle die Handelsrichtung basierend auf dem MT5-Typ
      // MT5 type: 0 = BUY, 1 = SELL
      const direction = mt5Trade.type === 0 ? 'long' : 'short';
      
      // Verwende die gleiche Datumsformatierung wie im Trade Import Service
      const formatDate = (dateInput: Date | string): string => {
        if (!dateInput) {
          console.error('Error: Empty date input');
          return '';
        }
        
        // Debug logs für Zeitformatierung
        console.log('MT5 Original Date Input:', dateInput);
        
        // Konvertiere Date-Objekt zu String wenn nötig
        const dateStr = dateInput instanceof Date ? 
                      dateInput.toISOString() : 
                      String(dateInput).trim();
        
        console.log('MT5 Date String für Parsing:', dateStr);
        
        // Versuche zuerst die MT5-spezifischen Formate (exakt wie im Dateiimport)
        // MT5 sendet Zeitstempel als 'YYYY.MM.DD HH:mm:ss' oder 'YYYY.MM.DD HH:mm'
        const mt5Formats = [
          'YYYY.MM.DD HH:mm:ss',
          'YYYY.MM.DD HH:mm'
        ];
        
        for (const format of mt5Formats) {
          console.log('Trying MT5 format:', format);
          const parsed = dayjs(dateStr, format);
          if (parsed.isValid()) {
            const result = parsed.format('YYYY-MM-DDTHH:mm');
            console.log('MT5: Successfully parsed date with format', format);
            console.log('MT5: Result:', result);
            return result;
          }
        }
        
        // Wenn die MT5-Formate nicht passen, probiere andere Formate
        const otherFormats = [
          'YYYY-MM-DDTHH:mm:ss.SSSZ',  // ISO String format
          'YYYY-MM-DDTHH:mm:ss.SSS',   // ISO without timezone
          'YYYY-MM-DD HH:mm:ss',       // Standard SQL format
          'YYYY-MM-DDTHH:mm'           // Target BrainDumper format
        ];
        
        for (const format of otherFormats) {
          console.log('Trying other format:', format);
          const parsed = dayjs(dateStr, format);
          if (parsed.isValid()) {
            const result = parsed.format('YYYY-MM-DDTHH:mm');
            console.log('Successfully parsed with format', format);
            console.log('Result:', result);
            return result;
          }
        }
        
        // Wenn das fehlschlägt und es wie ein ISO-Format aussieht, verwende direkte Extraktion
        if (typeof dateStr === 'string' && dateStr.includes('T') && dateStr.length >= 16) {
          const justDateAndTime = dateStr.substring(0, 16); // Nimm nur YYYY-MM-DDTHH:mm
          console.log('Using direct ISO format extraction:', justDateAndTime);
          return justDateAndTime;
        }
        
        // Als letztes Fallback, versuche Regex wie im Trade Import Service
        const match = dateStr.match(/^(\d{4})\.(\d{2})\.(\d{2})\s+(\d{2}):(\d{2})(?::(\d{2}))?$/);
        if (match) {
          const [_, year, month, day, hour, minute] = match;
          const result = `${year}-${month}-${day}T${hour}:${minute}`;
          console.log('Parsed date using regex');
          console.log('Result:', result);
          return result;
        }
        
        // Wenn alles fehlschlägt, versuche die direkte Verarbeitung
        try {
          const parsed = dayjs(dateStr);
          if (parsed.isValid()) {
            const result = parsed.format('YYYY-MM-DDTHH:mm');
            console.log('Parsed with automatic detection:', result);
            return result;
          }
        } catch (error) {
          console.error('Failed to parse date:', error);
        }
        
        console.error('Failed to parse date with all methods:', dateStr);
        return '';
      };
      
      const entryDate = mt5Trade.time_open;
      
      // Stelle sicher, dass wir immer ein Schlussdatum haben
      let exitDate;
      if (mt5Trade.time_close) {
        exitDate = mt5Trade.time_close;
      } else {
        // Wenn kein Schlussdatum vorhanden ist, verwende das aktuelle Datum
        exitDate = new Date();
        console.log('Kein Schlussdatum für Trade gefunden, verwende aktuelles Datum');
      }
      
      // Berechne Gebühren (Commission + Swap)
      const fees = (mt5Trade.commission || 0) + (mt5Trade.swap || 0);
      
      // Berechne den Brutto-P&L
      const grossPnl = mt5Trade.profit || 0;
      
      // Berechne den Netto-P&L (Profit minus Gebühren)
      const netPnl = grossPnl - Math.abs(fees);
      
      // Stelle sicher, dass exitPrice immer einen Wert hat
      const exitPrice = mt5Trade.price_close !== undefined ? 
                        mt5Trade.price_close : 
                        (mt5Trade.price_current || mt5Trade.price_open);
      
      // Erstelle das Trade-Objekt gemäß der Trade-Schnittstelle von BrainDumper
      return {
        id: tradeId,
        accountId: accountId,
        timestamp: formatDate(entryDate),
        closingTime: formatDate(exitDate),
        pair: mt5Trade.symbol || 'EURUSD',
        direction: direction,
        entryPrice: mt5Trade.price_open,
        exitPrice: exitPrice,
        quantity: mt5Trade.volume,
        grossPnl: grossPnl,
        fees: Math.abs(fees),
        netPnl: netPnl,
        // Speichere die MT5-Ticket-Nummer im Kommentar, um Duplikate einfacher zu erkennen
        notes: `MT5 Trade #${mt5Trade.ticket || 'unknown'}. ${mt5Trade.comment || ''}`,
        tags: ['MT5-Import'],
        images: [],
        status: mt5Trade.is_open === true ? 'open' : 'closed',
        stopLoss: mt5Trade.sl || 0,
        takeProfit: mt5Trade.tp || 0,
        emotion: 'neutral',  // Standard-Emotion
        session: null,
        riskRewardRatio: ""  // Leeres Risk-to-Reward-Verhältnis statt "1:1"
      };
    });
  }

  /**
   * Sync trades from MT5 to the account
   */
  async syncTrades(account: Account): Promise<{
    trades: Trade[],
    lastSyncTime: string
  }> {
    try {
      // Detailliertes Logging zur Problemdiagnose
      console.log('Starte Sync für Account:', account.id, account.name);
      console.log('MT5 Connection Details:', JSON.stringify(account.mt5Connection, null, 2));
      
      // Validierung der MT5-Verbindungsdaten mit spezifischeren Fehlermeldungen
      if (!account.mt5Connection) {
        throw new Error('MT5 connection missing');
      }
      
      if (!account.mt5Connection.enabled) {
        throw new Error('MT5 connection not enabled');
      }
      
      if (!account.mt5Connection.login) {
        throw new Error('MT5 login missing');
      }
      
      if (!account.mt5Connection.password) {
        throw new Error('MT5 password missing');
      }
      
      if (!account.mt5Connection.server) {
        throw new Error('MT5 server name missing');
      }

      // Determine the start date for sync - Verwende einen längeren Zeitraum zum Testen
      // Statt nur seit der letzten Synchronisierung, nehmen wir die letzten 24 Stunden
      const startDate = new Date();
      startDate.setHours(startDate.getHours() - 24); // 24 Stunden zurück
      
      console.log('Sync Zeitbereich:', startDate.toISOString(), 'bis jetzt');

      // Get trades from MT5
      const mt5Trades = await this.getTrades(account, startDate);
      console.log(`${mt5Trades.length} Trades vom MT5-Server erhalten`);
      
      if (mt5Trades.length === 0) {
        console.log('Keine Trades gefunden! Versuche eine längere Zeitspanne...');
        // Versuche mit einer noch längeren Zeitspanne
        const extendedStartDate = new Date();
        extendedStartDate.setDate(extendedStartDate.getDate() - 7); // 7 Tage zurück
        console.log('Erweiterter Sync Zeitbereich:', extendedStartDate.toISOString(), 'bis jetzt');
        
        // Erneuter Versuch mit längerem Zeitraum
        const extendedTrades = await this.getTrades(account, extendedStartDate);
        console.log(`${extendedTrades.length} Trades vom MT5-Server erhalten (erweiterter Zeitraum)`);
        
        if (extendedTrades.length > 0) {
          // Wir haben Trades gefunden, verwende diese
          const brainDumperTrades = this.convertMT5TradesToBrainDumperFormat(extendedTrades, account.id);
          const lastSyncTime = new Date().toISOString();
          
          return {
            trades: brainDumperTrades,
            lastSyncTime
          };
        }
      }
      
      // Convert to BrainDumper format
      const brainDumperTrades = this.convertMT5TradesToBrainDumperFormat(mt5Trades, account.id);
      
      // Update last sync time
      const lastSyncTime = new Date().toISOString();
      
      return {
        trades: brainDumperTrades,
        lastSyncTime
      };
    } catch (error) {
      console.error('Error syncing MT5 trades:', error);
      throw error;
    }
  }
}

export const mt5Service = new Mt5Service();
