/**
 * Environment variables validation
 * Fallback values provided for development situations where .env isn't loading properly
 */
const FALLBACK_PUBLISHABLE_KEY = 'pk_live_51QyuYHExiQB1c6YFAF5WxEaDc9d3KKt6oYH8MBGdNbslLorKu78rFVWth3k9GjdGGAbCjiQh5LuH4kP3jsCBdjNV00lXR7kci5';
const FALLBACK_PRO_PRICE_ID = 'price_1QzlYSExiQB1c6YF0HZvjax4';
const FALLBACK_ENTERPRISE_PRICE_ID = 'price_1QyubCRHMvNBAr3HRcfy9V6J';

// Get environment variables or use fallbacks for development
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || FALLBACK_PUBLISHABLE_KEY;
const PRO_PRICE_ID = process.env.REACT_APP_STRIPE_PRO_PRICE_ID || FALLBACK_PRO_PRICE_ID;
const ENTERPRISE_PRICE_ID = process.env.REACT_APP_STRIPE_ENTERPRISE_PRICE_ID || FALLBACK_ENTERPRISE_PRICE_ID;

// Removed console.log statements for production

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // Only log in development mode
  if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
    console.warn('WARNING: Using fallback Stripe keys because environment variables are missing. This should only be used in development.');
  }
}

// Removed stripe initialization from here - using the lazy-loading pattern in stripeService.ts instead

export { PRO_PRICE_ID, ENTERPRISE_PRICE_ID, getStripePublishableKey };

function getStripePublishableKey() {
  return STRIPE_PUBLISHABLE_KEY;
}
