import { useAccountStore } from '../store/accountStore';
import { useTradeStore } from '../store/tradeStore';
import { mt5Service } from './mt5Service';
import { Account } from '../types/account';

class Mt5SyncService {
  private syncInterval: NodeJS.Timeout | null = null;
  private isSyncing: boolean = false;
  private lastCheckTime: number = 0;
  
  /**
   * Initialize the background sync service
   */
  initialize(): void {
    if (this.syncInterval) {
      // Already initialized
      return;
    }
    
    console.log('Initializing MT5 sync service');
    
    // Check for trades to sync every minute
    this.syncInterval = setInterval(() => {
      this.checkAccountsForSync();
    }, 60000); // 1 minute
    
    // Start an initial check
    setTimeout(() => {
      this.checkAccountsForSync();
    }, 5000);
  }
  
  /**
   * Stop the background sync service
   */
  shutdown(): void {
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
      this.syncInterval = null;
      console.log('MT5 sync service stopped');
    }
  }
  
  /**
   * Check all accounts that have MT5 integration enabled and determine if they need syncing
   */
  private async checkAccountsForSync(): Promise<void> {
    if (this.isSyncing) {
      // Already running a sync check
      return;
    }
    
    try {
      this.isSyncing = true;
      
      // Only check once every 30 seconds at most
      const now = Date.now();
      if (now - this.lastCheckTime < 30000) {
        return;
      }
      this.lastCheckTime = now;
      
      // Get all accounts
      const accounts = useAccountStore.getState().accounts;
      
      // Filter accounts with MT5 integration enabled and auto-sync enabled
      const accountsToSync = accounts.filter(account => 
        account.mt5Connection?.enabled && 
        account.mt5Connection.autoSync &&
        account.mt5Connection.login && 
        account.mt5Connection.password && 
        account.mt5Connection.server
      );
      
      if (accountsToSync.length === 0) {
        return;
      }
      
      console.log(`Checking ${accountsToSync.length} accounts for MT5 auto-sync`);
      
      // Check each account to see if it's time to sync
      for (const account of accountsToSync) {
        await this.checkAccountForSync(account);
      }
    } catch (error) {
      console.error('Error in MT5 sync service check:', error);
    } finally {
      this.isSyncing = false;
    }
  }
  
  /**
   * Check if a specific account needs to be synced
   */
  private async checkAccountForSync(account: Account): Promise<void> {
    try {
      if (!account.mt5Connection?.lastSyncTime) {
        // Account has never been synced before, sync it now
        console.log(`Syncing MT5 account ${account.name} (${account.id}) for the first time`);
        await this.syncAccount(account);
        return;
      }
      
      // Get the interval in minutes (default to 60 if not specified)
      const intervalMinutes = account.mt5Connection.syncInterval || 60;
      
      // Calculate the next sync time
      const lastSyncTime = new Date(account.mt5Connection.lastSyncTime).getTime();
      const nextSyncTime = lastSyncTime + (intervalMinutes * 60 * 1000);
      
      // Check if it's time to sync
      if (Date.now() >= nextSyncTime) {
        console.log(`Auto-syncing MT5 account ${account.name} (${account.id})`);
        await this.syncAccount(account);
      }
    } catch (error) {
      console.error(`Error checking account ${account.id} for sync:`, error);
    }
  }
  
  /**
   * Sync trades for an account
   */
  private async syncAccount(account: Account): Promise<void> {
    try {
      // Get the sync results
      const result = await mt5Service.syncTrades(account);
      
      // Check if there are any trades to import
      if (result.trades.length > 0) {
        // Import the trades
        await useTradeStore.getState().batchImportTrades(
          result.trades,
          account.id,
          true,
          account.currentBalance
        );
        
        console.log(`Imported ${result.trades.length} trades from MT5 for account ${account.name}`);
        
        // After importing trades, calculate the new account balance
        const currentBalance = this.calculateCurrentBalance(account.id);
        
        // Update the account with the new balance
        await useAccountStore.getState().updateAccount(account.id, {
          currentBalance
        });
        
        console.log(`Updated account ${account.name} with new balance: ${currentBalance}`);
      }
      
      // Update the last sync time
      await useAccountStore.getState().updateAccount(account.id, {
        mt5Connection: {
          ...account.mt5Connection,
          enabled: true,
          lastSyncTime: result.lastSyncTime
        }
      });
    } catch (error) {
      console.error(`Error syncing account ${account.id}:`, error);
      throw error;
    }
  }
  
  /**
   * Calculate current balance based on trades
   */
  private calculateCurrentBalance(accountId: string): number {
    try {
      const accountStore = useAccountStore.getState();
      const tradeStore = useTradeStore.getState();
      
      // Get the account
      const account = accountStore.accounts.find(acc => acc.id === accountId);
      if (!account) {
        throw new Error(`Account with ID ${accountId} not found`);
      }
      
      // Get all trades for this account
      const trades = tradeStore.trades.filter(trade => trade.accountId === accountId);
      
      // Calculate total profit/loss
      const totalPnL = trades.reduce((sum, trade) => sum + (trade.netPnl || 0), 0);
      
      // Calculate new current balance (initial balance + P&L)
      const currentBalance = (account.initialBalance || 0) + totalPnL;
      
      return Number(currentBalance.toFixed(2));
    } catch (error) {
      console.error('Error calculating account balance:', error);
      return 0;
    }
  }
  
  /**
   * Manually trigger a sync for all MT5-enabled accounts
   */
  async syncAllAccounts(): Promise<void> {
    try {
      const accounts = useAccountStore.getState().accounts;
      
      // Filter accounts with MT5 integration enabled
      const accountsToSync = accounts.filter(account => 
        account.mt5Connection?.enabled && 
        account.mt5Connection.login && 
        account.mt5Connection.password && 
        account.mt5Connection.server
      );
      
      if (accountsToSync.length === 0) {
        console.log('No MT5-enabled accounts found');
        return;
      }
      
      console.log(`Manually syncing ${accountsToSync.length} MT5 accounts`);
      
      for (const account of accountsToSync) {
        await this.syncAccount(account);
      }
      
      console.log('Manual MT5 sync completed');
    } catch (error) {
      console.error('Error in manual MT5 sync:', error);
      throw error;
    }
  }
  
  /**
   * Manually trigger a sync for a specific account
   */
  async syncSingleAccount(accountId: string): Promise<void> {
    try {
      const accounts = useAccountStore.getState().accounts;
      
      // Find the account with the provided ID
      const account = accounts.find(acc => acc.id === accountId);
      
      if (!account) {
        throw new Error(`Account with ID ${accountId} not found`);
      }
      
      // Check if MT5 integration is enabled
      if (!account.mt5Connection?.enabled || 
          !account.mt5Connection.login || 
          !account.mt5Connection.password || 
          !account.mt5Connection.server) {
        throw new Error('MT5 integration not properly configured for this account');
      }
      
      // Sync the account
      await this.syncAccount(account);
      
      return;
    } catch (error) {
      console.error(`Error syncing account ${accountId}:`, error);
      throw error;
    }
  }
}

export const mt5SyncService = new Mt5SyncService();
