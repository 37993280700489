import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Account, DEFAULT_ACCOUNT } from '../types/account';
import { firebaseService } from '../services/firebaseService';
import { getAuth } from 'firebase/auth';
import { useTradeStore } from './tradeStore';

interface AccountStore {
  accounts: Account[];
  selectedAccountId: string | null;
  addAccount: (account: Partial<Omit<Account, 'id' | 'createdAt'>>) => Promise<void>;
  updateAccount: (id: string, updates: Partial<Omit<Account, 'id' | 'createdAt'>>) => Promise<void>;
  deleteAccount: (id: string) => Promise<void>;
  setSelectedAccount: (accountId: string | null) => Promise<void>;
  updateAccountBalance: (id: string, newBalance: number) => Promise<void>;
  setFavoriteAccount: (id: string) => Promise<void>;
  getFavoriteAccount: () => Account | null;
  ensureFavoriteAccount: () => Promise<void>;
  resetStore: () => void;
  setAccounts: (accounts: Account[]) => void;
}

// Clear any existing persisted data
if (typeof window !== 'undefined') {
  localStorage.removeItem('account-store');
}

export const useAccountStore = create<AccountStore>()(
  persist(
    (set, get) => ({
      accounts: [],
      selectedAccountId: null,
      
      setAccounts: (accounts) => {
        console.log('Setting accounts:', accounts);
        set({ accounts });
      },

      addAccount: async (accountData) => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error('No user ID found');
          return;
        }

        console.log("Adding account with data:", JSON.stringify(accountData, null, 2));

        const currentAccounts = get().accounts;
        const isFirstAccount = currentAccounts.length === 0;
        
        // If this is the first account or there are no favorite accounts yet,
        // mark this one as favorite
        const hasFavorite = currentAccounts.some(account => account.isFavorite);
        const shouldBeFavorite = isFirstAccount || !hasFavorite;

        // If this account will be favorite, set all other accounts to not favorite
        let updatedAccounts = currentAccounts;
        if (shouldBeFavorite && currentAccounts.length > 0) {
          updatedAccounts = currentAccounts.map(account => ({
            ...account,
            isFavorite: false
          }));
        }

        // Process MT5 connection data if present
        let mt5Connection = undefined;
        if (accountData.mt5Connection) {
          console.log("Processing MT5 connection data for new account");
          
          // Ensure all MT5 fields are of the correct type
          mt5Connection = {
            enabled: Boolean(accountData.mt5Connection.enabled),
            login: accountData.mt5Connection.login ? 
                  Number(accountData.mt5Connection.login) : undefined,
            password: accountData.mt5Connection.password || undefined,
            server: accountData.mt5Connection.server || undefined,
            lastSyncTime: accountData.mt5Connection.lastSyncTime || undefined
          };
          
          console.log("Processed MT5 connection data:", {
            ...mt5Connection,
            password: mt5Connection.password ? "[ENCRYPTED]" : undefined
          });
        }

        const newAccount = {
          ...DEFAULT_ACCOUNT,
          ...accountData,
          mt5Connection, // Override with the processed MT5 connection data
          id: Math.random().toString(36).substr(2, 9),
          createdAt: new Date().toISOString(),
          isFavorite: shouldBeFavorite
        };

        console.log("New account to save (without password):", {
          ...newAccount,
          mt5Connection: newAccount.mt5Connection ? {
            ...newAccount.mt5Connection,
            password: newAccount.mt5Connection.password ? "[ENCRYPTED]" : undefined
          } : undefined
        });

        try {
          // First update Firestore
          await firebaseService.updateUserAccounts(userId, [...updatedAccounts, newAccount]);
          
          // Then update local state
          set((state) => ({
            accounts: [...updatedAccounts, newAccount],
            selectedAccountId: shouldBeFavorite ? newAccount.id : (state.selectedAccountId || newAccount.id)
          }));
          
          console.log("Account saved successfully!");
        } catch (error) {
          console.error("Error saving account to Firestore:", error);
          if (error instanceof Error) {
            console.error("Error details:", error.message, error.stack);
          }
          throw error; // Re-throw to be handled by the caller
        }
      },

      updateAccount: async (id, updates) => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error('No user ID found');
          return;
        }

        const updatedAccounts = get().accounts.map(account =>
          account.id === id ? { ...account, ...updates } : account
        );

        // First update Firestore
        await firebaseService.updateUserAccounts(userId, updatedAccounts);
        
        // Then update local state
        set({ accounts: updatedAccounts });
      },

      deleteAccount: async (id) => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error('No user ID found');
          return;
        }

        try {
          // Delete all trades for this account using the trade store's batch method
          await useTradeStore.getState().deleteTradesByAccountId(id);
          
          // Now handle account deletion
          const newAccounts = get().accounts.filter(account => account.id !== id);
          
          // If only one account remains, make it the favorite
          if (newAccounts.length === 1 && !newAccounts[0].isFavorite) {
            newAccounts[0].isFavorite = true;
          }
          
          // Update Firestore
          await firebaseService.updateUserAccounts(userId, newAccounts);
          
          // Update local state
          set((state) => ({
            accounts: newAccounts,
            selectedAccountId: state.selectedAccountId === id 
              ? (newAccounts[0]?.id || null)
              : state.selectedAccountId
          }));
        } catch (error) {
          console.error('Error deleting account:', error);
          throw error;
        }
      },

      setSelectedAccount: async (accountId) => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error('No user ID found');
          return;
        }

        set({ selectedAccountId: accountId });
      },

      updateAccountBalance: async (id, newBalance) => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error('No user ID found');
          return;
        }

        const updatedAccounts = get().accounts.map(account =>
          account.id === id ? { ...account, currentBalance: newBalance } : account
        );

        // First update Firestore
        await firebaseService.updateUserAccounts(userId, updatedAccounts);
        
        // Then update local state
        set({ accounts: updatedAccounts });
      },

      setFavoriteAccount: async (id) => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error('No user ID found');
          return;
        }

        // Ensure only one account is favorite - set all to false, then set the selected one to true
        const updatedAccounts = get().accounts.map(account => ({
          ...account,
          isFavorite: account.id === id
        }));

        // First update Firestore
        await firebaseService.updateUserAccounts(userId, updatedAccounts);
        
        // Then update local state
        set((state) => ({
          accounts: updatedAccounts,
          selectedAccountId: id // Automatically select the favorite account
        }));
      },

      getFavoriteAccount: () => {
        return get().accounts.find(account => account.isFavorite) || null;
      },

      ensureFavoriteAccount: async () => {
        const accounts = get().accounts;
        
        // If there are no accounts, nothing to do
        if (accounts.length === 0) return;
        
        // Check if there's already a favorite account
        const hasFavorite = accounts.some(account => account.isFavorite);
        
        // If no favorite account exists, set the first account as favorite
        if (!hasFavorite && accounts.length > 0) {
          await get().setFavoriteAccount(accounts[0].id);
        }
      },

      resetStore: () => {
        console.log('Resetting account store');
        set({ accounts: [], selectedAccountId: null });
      }
    }),
    {
      name: 'account-store',
      version: 1,
    }
  )
);
