import { create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { firebaseService } from '../services/firebaseService';

interface UserLevel {
  level: number;
  points: number;
  pointsToNextLevel: number;
  completedChallenges: string[];
}

interface UserLevelState {
  userLevel: UserLevel;
  isLoading: boolean;
  error: string | null;
  
  addPoints: (userId: string, points: number, challengeId: string | null) => Promise<void>;
  resetStore: () => void;
  loadUserLevel: (userId: string) => Promise<void>;
}

const POINTS_PER_LEVEL = 200; // Points needed to level up

// Calculate points needed for the next level
const calculatePointsToNextLevel = (level: number, points: number): number => {
  const totalPointsForNextLevel = level * POINTS_PER_LEVEL;
  return totalPointsForNextLevel - points;
};

type UserLevelPersist = {
  userLevel: UserLevel;
};

const persistOptions: PersistOptions<UserLevelState, UserLevelPersist> = {
  name: 'user-level-storage',
  partialize: (state) => ({ userLevel: state.userLevel }),
};

export const useUserLevelStore = create<UserLevelState>()(
  persist(
    (set, get) => ({
      userLevel: {
        level: 1,
        points: 0,
        pointsToNextLevel: POINTS_PER_LEVEL,
        completedChallenges: [],
      },
      isLoading: false,
      error: null,

      addPoints: async (userId: string, points: number, challengeId: string | null) => {
        set({ isLoading: true, error: null });
        try {
          const { userLevel } = get();
          
          // Handle case when we just want to add points without tracking a challenge
          let newCompletedChallenges = [...userLevel.completedChallenges];
          
          // Only add challenge to completed list if a valid challengeId is provided
          if (challengeId !== null) {
            // Check if challenge is already completed
            if (userLevel.completedChallenges.includes(challengeId)) {
              set({ isLoading: false });
              return;
            }
            
            // Add this challenge to completed list
            newCompletedChallenges = [...userLevel.completedChallenges, challengeId];
          }
          
          // Add points
          const newPoints = userLevel.points + points;
          
          // Calculate new level based on points
          let newLevel = userLevel.level;
          while (newPoints >= newLevel * POINTS_PER_LEVEL) {
            newLevel++;
          }
          
          // Calculate points needed for next level
          const newPointsToNextLevel = calculatePointsToNextLevel(newLevel, newPoints);
          
          const updatedUserLevel = {
            level: newLevel,
            points: newPoints,
            pointsToNextLevel: newPointsToNextLevel,
            completedChallenges: newCompletedChallenges,
          };
          
          // Update in Firebase
          await firebaseService.updateUserLevel(userId, updatedUserLevel);
          
          set({
            userLevel: updatedUserLevel,
            isLoading: false,
          });
        } catch (error) {
          console.error('Error adding points:', error);
          set({
            error: error instanceof Error ? error.message : 'Unknown error occurred',
            isLoading: false,
          });
        }
      },
      
      loadUserLevel: async (userId: string) => {
        set({ isLoading: true, error: null });
        try {
          const userLevelData = await firebaseService.getUserLevel(userId);
          
          if (userLevelData) {
            set({
              userLevel: userLevelData,
              isLoading: false,
            });
          } else {
            // Initialize with default values if no data exists
            const defaultUserLevel = {
              level: 1,
              points: 0,
              pointsToNextLevel: POINTS_PER_LEVEL,
              completedChallenges: [],
            };
            
            await firebaseService.updateUserLevel(userId, defaultUserLevel);
            
            set({
              userLevel: defaultUserLevel,
              isLoading: false,
            });
          }
        } catch (error) {
          console.error('Error loading user level:', error);
          set({
            error: error instanceof Error ? error.message : 'Unknown error occurred',
            isLoading: false,
          });
        }
      },
      
      resetStore: () => {
        set({
          userLevel: {
            level: 1,
            points: 0,
            pointsToNextLevel: POINTS_PER_LEVEL,
            completedChallenges: [],
          },
          isLoading: false,
          error: null,
        });
      },
    }),
    persistOptions
  )
);
