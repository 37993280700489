import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Trade, TradeStats, DayOfWeek } from '../types/trade';
import { firebaseService } from '../services/firebaseService';
import { getAuth } from 'firebase/auth';

const initialStats: TradeStats = {
  totalTrades: 0,
  winningTrades: 0,
  losingTrades: 0,
  breakEvenTrades: 0,
  totalPnl: 0,
  averagePnl: 0,
  bestTrade: 0,
  worstTrade: 0,
  winRate: 0,
  mostTradedPair: '',
  mostProfitablePair: '',
  profitFactor: 0,
  averageWinningTrade: 0,
  averageLosingTrade: 0,
  currentStreak: 0,
  currentStreakType: 'none',
  dayPerformance: {
    'Monday': { totalPnl: 0, trades: 0 },
    'Tuesday': { totalPnl: 0, trades: 0 },
    'Wednesday': { totalPnl: 0, trades: 0 },
    'Thursday': { totalPnl: 0, trades: 0 },
    'Friday': { totalPnl: 0, trades: 0 },
    'Saturday': { totalPnl: 0, trades: 0 },
    'Sunday': { totalPnl: 0, trades: 0 }
  }
};

interface TradeStore {
  trades: Trade[];
  stats: TradeStats;
  loading: boolean;
  addTrade: (trade: Trade) => Promise<string>;
  updateTrade: (id: string, updatedTrade: Partial<Trade>) => Promise<void>;
  deleteTrade: (id: string) => Promise<void>;
  deleteTradesByAccountId: (accountId: string) => Promise<boolean>;
  calculateStats: () => void;
  getTradesByAccount: (accountId: string) => Trade[];
  getStatsByAccount: (accountId: string) => TradeStats;
  resetStore: () => void;
  setTrades: (trades: Trade[]) => void;
  batchImportTrades: (trades: Trade[], accountId: string, updateBalance: boolean, currentBalance: number) => Promise<any>;
}

const calculateStats = (trades: Trade[]): TradeStats => {
  const winningTrades = trades.filter(trade => trade.netPnl > 0);
  const losingTrades = trades.filter(trade => trade.netPnl < 0);
  const breakEvenTrades = trades.filter(trade => trade.netPnl === 0);

  const totalPnl = trades.reduce((sum, trade) => sum + trade.netPnl, 0);
  const averagePnl = trades.length > 0 ? totalPnl / trades.length : 0;
  const bestTrade = trades.length > 0 ? Math.max(...trades.map(t => t.netPnl)) : 0;
  const worstTrade = trades.length > 0 ? Math.min(...trades.map(t => t.netPnl)) : 0;
  const winRate = trades.length > 0 ? (winningTrades.length / trades.length) * 100 : 0;

  // Calculate profit factor
  const totalWinning = winningTrades.reduce((sum, t) => sum + t.netPnl, 0);
  const totalLosing = Math.abs(losingTrades.reduce((sum, t) => sum + t.netPnl, 0));
  const profitFactor = totalLosing === 0 ? totalWinning : totalWinning / totalLosing;

  // Calculate average trades
  const averageWinningTrade = winningTrades.length > 0 
    ? totalWinning / winningTrades.length 
    : 0;
  const averageLosingTrade = losingTrades.length > 0 
    ? totalLosing / losingTrades.length * -1
    : 0;

  // Calculate current streak
  let currentStreak = 0;
  let currentStreakType: 'winning' | 'losing' | null = null;
  
  if (trades.length > 0) {
    const sortedTrades = [...trades].sort((a, b) => 
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    );
    
    const firstTrade = sortedTrades[0];
    currentStreakType = firstTrade.netPnl > 0 ? 'winning' : firstTrade.netPnl < 0 ? 'losing' : null;
    
    if (currentStreakType) {
      currentStreak = 1;
      for (let i = 1; i < sortedTrades.length; i++) {
        const trade = sortedTrades[i];
        const isWinning = trade.netPnl > 0;
        
        if ((isWinning && currentStreakType === 'winning') ||
            (!isWinning && currentStreakType === 'losing')) {
          currentStreak++;
        } else {
          break;
        }
      }
    }
  }

  // Initialize day performance
  const dayPerformance: Record<DayOfWeek, { totalPnl: number; trades: number }> = {
    'Monday': { totalPnl: 0, trades: 0 },
    'Tuesday': { totalPnl: 0, trades: 0 },
    'Wednesday': { totalPnl: 0, trades: 0 },
    'Thursday': { totalPnl: 0, trades: 0 },
    'Friday': { totalPnl: 0, trades: 0 },
    'Saturday': { totalPnl: 0, trades: 0 },
    'Sunday': { totalPnl: 0, trades: 0 }
  };

  // Calculate day performance
  trades.forEach(trade => {
    try {
      const date = new Date(trade.timestamp);
      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        console.error('Invalid timestamp for trade:', trade);
        return;
      }
      const day = date.toLocaleDateString('en-US', { weekday: 'long' }) as DayOfWeek;
      dayPerformance[day].totalPnl += trade.netPnl;
      dayPerformance[day].trades += 1;
    } catch (error) {
      console.error('Error processing trade for day performance:', error, trade);
    }
  });

  // Calculate most traded and profitable pairs
  const pairStats = trades.reduce((acc, trade) => {
    if (!acc[trade.pair]) {
      acc[trade.pair] = { count: 0, profit: 0 };
    }
    acc[trade.pair].count++;
    acc[trade.pair].profit += trade.netPnl;
    return acc;
  }, {} as Record<string, { count: number; profit: number }>);

  const mostTradedPair = Object.entries(pairStats)
    .reduce((a, b) => a[1].count > b[1].count ? a : b, ['', { count: 0, profit: 0 }])[0];

  const mostProfitablePair = Object.entries(pairStats)
    .reduce((a, b) => a[1].profit > b[1].profit ? a : b, ['', { count: 0, profit: 0 }])[0];

  return {
    totalTrades: trades.length,
    winningTrades: winningTrades.length,
    losingTrades: losingTrades.length,
    breakEvenTrades: breakEvenTrades.length,
    totalPnl,
    averagePnl,
    bestTrade,
    worstTrade,
    winRate,
    mostTradedPair,
    mostProfitablePair,
    profitFactor,
    averageWinningTrade,
    averageLosingTrade,
    currentStreak,
    currentStreakType: currentStreakType || 'none',
    dayPerformance
  };
};

// Clear any existing persisted data
if (typeof window !== 'undefined') {
  localStorage.removeItem('trade-storage');
}

export const useTradeStore = create<TradeStore>()(
  persist(
    (set, get) => ({
      trades: [],
      stats: initialStats,
      loading: false,

      setTrades: (trades) => {
        set({ trades, stats: calculateStats(trades) });
      },

      addTrade: async (trade: Trade) => {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          throw new Error('Must be logged in to add trades');
        }

        // Save to Firestore first
        try {
          const tradeId = await firebaseService.createTrade(user.uid, trade);
          
          // Get the updated trades list from Firestore
          const trades = await firebaseService.getUserTrades(user.uid);
          
          // Update local state with the fresh data
          set({
            trades,
            stats: calculateStats(trades)
          });
          
          return tradeId;
        } catch (error) {
          console.error('Failed to save trade to Firestore:', error);
          throw error;
        }
      },

      updateTrade: async (id: string, updatedTrade: Partial<Trade>) => {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          throw new Error('Must be logged in to update trades');
        }

        // Update local state first
        set(state => {
          const newTrades = state.trades.map(trade => 
            trade.id === id ? { ...trade, ...updatedTrade } : trade
          );
          return {
            trades: newTrades,
            stats: calculateStats(newTrades)
          };
        });

        // Then update in Firestore
        try {
          await firebaseService.updateTrade(user.uid, id, updatedTrade);
        } catch (error) {
          console.error('Failed to update trade in Firestore:', error);
          // Rollback if Firestore update fails
          set(state => {
            const originalTrade = state.trades.find(t => t.id === id);
            if (!originalTrade) return state;
            
            const newTrades = state.trades.map(trade => 
              trade.id === id ? originalTrade : trade
            );
            return {
              trades: newTrades,
              stats: calculateStats(newTrades)
            };
          });
          throw error;
        }
      },

      deleteTrade: async (id) => {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          throw new Error('Must be logged in to delete trades');
        }

        // Delete from Firestore first
        await firebaseService.deleteTrade(user.uid, id);

        // Then update local state
        set(state => ({
          trades: state.trades.filter(trade => trade.id !== id)
        }));
        get().calculateStats();
      },

      deleteTradesByAccountId: async (accountId) => {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          throw new Error('Must be logged in to delete trades');
        }

        try {
          // First delete on Firebase
          await firebaseService.deleteTradesByAccountId(user.uid, accountId);
          
          // Then update local state in one operation
          set(state => ({
            trades: state.trades.filter(trade => trade.accountId !== accountId)
          }));
          
          // Update stats once
          get().calculateStats();
          
          return true;
        } catch (error) {
          console.error('Error batch deleting trades:', error);
          throw error;
        }
      },

      calculateStats: () => {
        set(state => ({
          stats: calculateStats(state.trades)
        }));
      },

      getTradesByAccount: (accountId) => {
        const state = get();
        return state.trades.filter(trade => trade.accountId === accountId);
      },

      getStatsByAccount: (accountId) => {
        const state = get();
        const accountTrades = state.trades.filter(trade => trade.accountId === accountId);
        return calculateStats(accountTrades);
      },

      resetStore: () => {
        set({
          trades: [],
          stats: initialStats,
          loading: false
        });
      },

      batchImportTrades: async (trades: Trade[], accountId: string, updateBalance: boolean = true, currentBalance: number = 0) => {
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (!user) {
          throw new Error('Must be logged in to batch import trades');
        }

        try {
          set({ loading: true });
          
          const result = await firebaseService.batchImportTrades(
            user.uid, 
            trades, 
            accountId, 
            updateBalance, 
            currentBalance
          );
          
          // Refresh trades after batch import
          const updatedTrades = await firebaseService.getUserTrades(user.uid);
          set({ 
            trades: updatedTrades,
            stats: calculateStats(updatedTrades),
            loading: false 
          });
          
          return result;
        } catch (error) {
          console.error('Error in batch import:', error);
          set({ loading: false });
          throw error;
        }
      },
    }),
    {
      name: 'trade-storage',
      storage: createJSONStorage(() => localStorage),
      version: 1
    }
  )
);
