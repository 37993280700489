import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';

import { AuthProvider } from './contexts/AuthContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import { ColorModeProvider } from './theme/ColorModeContext';
import { lazyLoad } from './utils/lazyLoad';
import { mt5SyncService } from './services/mt5SyncService';

// Configure dayjs
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

// Lazy load all page components for better code splitting
const Home = lazyLoad(() => import('./pages/Home'));
const MobileHome = lazyLoad(() => import('./pages/MobileHome'));
const MobileFeatures = lazyLoad(() => import('./pages/MobileFeatures'));
const MobilePricing = lazyLoad(() => import('./pages/MobilePricing'));
const MobileContact = lazyLoad(() => import('./pages/MobileContact'));
const MobileAppComingSoon = lazyLoad(() => import('./pages/MobileAppComingSoon'));
const Features = lazyLoad(() => import('./pages/Features'));
const Contact = lazyLoad(() => import('./pages/Contact'));
const Dashboard = lazyLoad(() => import('./pages/Dashboard'));
const Pricing = lazyLoad(() => import('./pages/Pricing'));
const Layout = lazyLoad(() => import('./components/Layout'));
const PrivateRoute = lazyLoad(() => import('./components/PrivateRoute'));
const VerifiedRoute = lazyLoad(() => import('./components/VerifiedRoute'));
const EmailVerification = lazyLoad(() => import('./components/EmailVerification'));
const Auth = lazyLoad(() => import('./pages/Auth'));
const Calendar = lazyLoad(() => import('./pages/Calendar'));
const Accounts = lazyLoad(() => import('./pages/Accounts'));
const Habits = lazyLoad(() => import('./pages/Habits'));
const Notebook = lazyLoad(() => import('./pages/Notebook'));
const NotebookView = lazyLoad(() => import('./pages/NotebookView'));
const Mindset = lazyLoad(() => import('./pages/Mindset'));
const Strategy = lazyLoad(() => import('./pages/Strategy'));
const TradingJournal = lazyLoad(() => import('./pages/TradingJournal'));
const Toolbox = lazyLoad(() => import('./pages/Toolbox'));
const BacktestingGame = lazyLoad(() => import('./pages/BacktestingGame'));
const Profile = lazyLoad(() => import('./pages/Profile'));
const CheckoutSuccess = lazyLoad(() => import('./pages/CheckoutSuccess'));
const SubscriptionRepair = lazyLoad(() => import('./pages/SubscriptionRepair'));
const Terms = lazyLoad(() => import('./pages/Terms'));
const PrivacyPolicy = lazyLoad(() => import('./pages/PrivacyPolicy'));
const RegistrationComplete = lazyLoad(() => import('./pages/RegistrationComplete'));

// ScrollToTop component to handle scrolling to top when navigating
function ScrollToTopComponent() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// Mobile detection component that redirects to mobile versions of pages on small screens
function MobileRedirect({ children, mobilePath }: { children: React.ReactNode, mobilePath: string }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  if (isMobile) {
    return <Navigate to={mobilePath} replace />;
  }
  
  return <>{children}</>;
}

// Mobile detection for protected routes
function MobileProtectedRoute({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  if (isMobile) {
    return <Navigate to="/mobile/app-coming-soon" replace />;
  }
  
  return <>{children}</>;
}

function App() {
  // Log environment variables for debugging
  useEffect(() => {
    console.log('Environment Variables Debug:');
    console.log('REACT_APP_STRIPE_PUBLISHABLE_KEY:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'Not found');
    console.log('REACT_APP_STRIPE_PRO_PRICE_ID:', process.env.REACT_APP_STRIPE_PRO_PRICE_ID || 'Not found');
    console.log('REACT_APP_FIREBASE_PROJECT_ID:', process.env.REACT_APP_FIREBASE_PROJECT_ID || 'Not found');
  }, []);

  return (
    <ColorModeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthProvider>
          <SubscriptionProvider>
            <Router>
              <CssBaseline />
              <ScrollToTopComponent />
              <Routes>
                {/* Public routes */}
                <Route path="/" element={
                  <MobileRedirect mobilePath="/mobile">
                    <Home />
                  </MobileRedirect>
                } />
                <Route path="/mobile" element={<MobileHome />} />
                <Route path="/mobile/app-coming-soon" element={<MobileAppComingSoon />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                <Route path="/login" element={<Navigate to="/auth" replace />} />
                <Route path="/signup" element={<Navigate to="/auth?tab=1" replace />} />
                <Route path="/features" element={
                  <MobileRedirect mobilePath="/mobile/features">
                    <Features />
                  </MobileRedirect>
                } />
                <Route path="/mobile/features" element={<MobileFeatures />} />
                <Route path="/pricing" element={
                  <MobileRedirect mobilePath="/mobile/pricing">
                    <Pricing />
                  </MobileRedirect>
                } />
                <Route path="/mobile/pricing" element={<MobilePricing />} />
                <Route path="/contact" element={
                  <MobileRedirect mobilePath="/mobile/contact">
                    <Contact />
                  </MobileRedirect>
                } />
                <Route path="/mobile/contact" element={<MobileContact />} />
                <Route path="/checkout-success" element={<CheckoutSuccess />} />
                <Route path="/subscription-repair" element={<SubscriptionRepair />} />
                <Route path="/registration-complete" element={<RegistrationComplete />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                
                {/* Protected routes */}
                <Route path="/app" element={
                  <MobileProtectedRoute>
                    <PrivateRoute>
                      <VerifiedRoute>
                        <Layout />
                      </VerifiedRoute>
                    </PrivateRoute>
                  </MobileProtectedRoute>
                }>
                  <Route index element={<Navigate to="/app/dashboard" replace />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="calendar" element={<Calendar />} />
                  <Route path="accounts" element={<Accounts />} />
                  <Route path="habits" element={<Habits />} />
                  <Route path="journal" element={<TradingJournal />} />
                  <Route path="notebook" element={<Notebook />} />
                  <Route path="notebook/:notebookId" element={<NotebookView />} />
                  <Route path="mindset" element={<Mindset />} />
                  <Route path="strategy" element={<Strategy />} />
                  <Route path="toolbox" element={<Toolbox />} />
                  <Route path="backtesting" element={<BacktestingGame />} />
                  <Route path="profile" element={<Profile />} />
                </Route>

                {/* Catch all route */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>
          </SubscriptionProvider>
        </AuthProvider>
      </LocalizationProvider>
    </ColorModeProvider>
  );
}

export default App;
