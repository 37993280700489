export interface Account {
  id: string;
  name: string;
  type?: 'demo' | 'live';
  broker?: string;
  currency: string;
  initialBalance?: number;
  currentBalance: number;
  createdAt: string;
  isFavorite?: boolean;
  riskSettings?: {
    maxPositionSize?: number;  // Maximum position size as percentage of account
    maxDailyLoss?: number;     // Maximum daily loss as percentage of account
    maxDrawdown?: number;      // Maximum drawdown as percentage of account
  };
  // MetaTrader 5 connection details
  mt5Connection?: {
    enabled: boolean;
    login?: number;      // MT5 account login
    password?: string;   // MT5 account password (encrypted)
    server?: string;     // MT5 server
    lastSyncTime?: string; // Last successful sync timestamp
    autoSync?: boolean;  // Auto-sync enabled
    syncInterval?: number; // Sync interval in minutes
  };
}

export const DEFAULT_ACCOUNT: Omit<Account, 'id'> = {
  name: 'New Account',
  type: 'demo',
  broker: '',
  currency: 'USD',
  initialBalance: 0,
  currentBalance: 0,
  createdAt: new Date().toISOString(),
  isFavorite: false,
  riskSettings: {
    maxPositionSize: 2,
    maxDailyLoss: 2,
    maxDrawdown: 5
  }
};
