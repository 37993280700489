import React, { Suspense } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';

// Loader component that displays while the lazy-loaded component is loading
const Loader = () => {
  const theme = useTheme();
  
  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      height="100%" 
      width="100%" 
      py={4}
    >
      <CircularProgress 
        size={40} 
        thickness={4} 
        sx={{ 
          color: theme.palette.primary.main,
        }} 
      />
    </Box>
  );
};

/**
 * Lazy loads a component with a suspense boundary and loading indicator
 * Uses improved chunk loading strategy with prefetch
 * @param importFunc - The import function that loads the component
 * @returns The lazy-loaded component wrapped in a Suspense
 */
export const lazyLoad = (importFunc: () => Promise<{ default: React.ComponentType<any> }>) => {
  // Enhance the import function with preload capability
  const EnhancedLazyComponent = React.lazy(() => {
    // Add a small delay to ensure critical resources load first
    return new Promise<{ default: React.ComponentType<any> }>(resolve => {
      // Use requestIdleCallback to load non-critical components during browser idle time
      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(() => {
          importFunc().then(module => resolve(module));
        }, { timeout: 2000 });
      } else {
        // Fallback for browsers without requestIdleCallback
        setTimeout(() => {
          importFunc().then(module => resolve(module));
        }, 100);
      }
    });
  });
  
  return (props: any) => (
    <Suspense fallback={<Loader />}>
      <EnhancedLazyComponent {...props} />
    </Suspense>
  );
};
