import { loadStripe } from '@stripe/stripe-js';
import { PlanType, SubscriptionData } from '../types/subscription';
import { getStripePublishableKey } from '../config/stripe';
import { functions, httpsCallable } from '../config/firebase';
import { auth } from '../config/firebase'; // Import auth from firebase config
import { firebaseService } from '../services/firebaseService'; // Import firebaseService

// Environment variable validation
const STRIPE_KEY = getStripePublishableKey();
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || 'trader-life-tracker';
const PRO_PRICE_ID = 'price_1QzlYSExiQB1c6YF0HZvjax4'; // Updated to production price ID
const ENTERPRISE_PRICE_ID = 'price_1QyubCRHMvNBAr3HRcfy9V6J'; // Hardcoded for now

// Skip actual API call and use mock data for development
const USE_MOCK_CHECKOUT = false; // Set to true to bypass CORS issues in development

// Validate critical configuration
let validationError = false;
if (!STRIPE_KEY) {
  // Handled silently in production
  if (process.env.NODE_ENV === 'development') {
    console.error('Stripe publishable key is missing');
  }
  validationError = true;
}

if (!FIREBASE_PROJECT_ID) {
  // Handled silently in production
  if (process.env.NODE_ENV === 'development') {
    console.error('Firebase project ID is missing');
  }
  validationError = true;
}

// Log all important configuration values
if (process.env.NODE_ENV === 'development') {
  console.log('StripeService Configuration:', {
    stripeKeyAvailable: !!STRIPE_KEY,
    firebaseProjectId: FIREBASE_PROJECT_ID,
    proPriceIdAvailable: !!PRO_PRICE_ID,
    enterprisePriceIdAvailable: !!ENTERPRISE_PRICE_ID,
    useMockCheckout: USE_MOCK_CHECKOUT
  });
}

if (validationError) {
  throw new Error('Critical configuration validation failed');
}

// Initialize Stripe lazily - only when needed
let stripePromise: Promise<any> | null = null;

const getStripe = () => {
  // Check if Stripe is already globally available to prevent multiple loading
  if ((window as any).Stripe) {
    if (!stripePromise) {
      // Use existing Stripe instance
      stripePromise = Promise.resolve((window as any).Stripe(STRIPE_KEY));
    }
    return stripePromise;
  }

  // Otherwise, load it normally
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_KEY);
  }
  return stripePromise;
};

// API URL - handle missing project ID gracefully
const API_BASE_URL = `https://us-central1-${FIREBASE_PROJECT_ID}.cloudfunctions.net`;

interface CreateCheckoutSessionOptions {
  priceId: string;
  userId: string;
  userEmail: string;
  isTrial?: boolean;  // Neuer Parameter für Trial-Aktivierung
}

/**
 * Service for Stripe payment and subscription functionality
 */
class StripeService {
  /**
   * Create a checkout session and redirect to Stripe Checkout
   */
  async createCheckoutSession({ priceId, userId, userEmail, isTrial = false }: CreateCheckoutSessionOptions): Promise<void> {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Creating checkout session for ${userId}, plan: ${priceId}, trial: ${isTrial}`);
      }
      
      const stripe = await getStripe();
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }

      // Client-side checkout
      if (process.env.NODE_ENV === 'development') {
        console.log('Using client-side checkout...');
      }
      
      // Set up CORS safely for Stripe
      try {
        // Serverless-Funktionsaufruf verwenden statt direkter Stripe-Integration
        const createCheckoutFn = httpsCallable(functions, 'createCheckoutSession');
        const result = await createCheckoutFn({
          priceId,
          userId,
          isTrial: isTrial, // Explizit das Trial-Flag übergeben
          successUrl: `${window.location.origin}/checkout-success?session_id={CHECKOUT_SESSION_ID}${isTrial ? '&trial=true' : ''}`,
          cancelUrl: `${window.location.origin}/pricing`
        });
        
        // Typ-Assertion für das Ergebnis
        const data = result.data as { sessionId: string };
        
        if (!data.sessionId) {
          throw new Error('No session ID returned from function');
        }
        
        // Mit sessionId zu Stripe Checkout weiterleiten
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId
        });
        
        if (error) {
          if (process.env.NODE_ENV === 'development') {
            console.error('Stripe redirect error:', error);
          }
          throw error;
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Error creating checkout session:', error);
        }
        throw error;
      }

    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error creating checkout session:', error);
      }
      throw error;
    }
  }

  /**
   * Get user's subscription data
   */
  async getSubscription(userId: string): Promise<SubscriptionData | null> {
    try {
      // In Entwicklungsumgebung: Firebase callable function verwenden
      if (process.env.NODE_ENV === 'development') {
        console.log('Fetching subscription data using Firebase callable function');
      }
      const getSubscriptionFn = httpsCallable(functions, 'getSubscription');
      const result = await getSubscriptionFn({ userId });
      // Type assertion für das Ergebnis
      return result.data as SubscriptionData;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error getting subscription:', error);
      }
      return null;
    }
  }

  /**
   * Create a customer portal session for managing subscription
   */
  async createPortalSession(userId: string): Promise<string> {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log('Creating portal session using Firebase callable function');
      }
      const createPortalSessionFn = httpsCallable(functions, 'createPortalSession');
      
      // Get the current user's email
      const user = auth.currentUser;
      const userEmail = user?.email || '';
      
      if (process.env.NODE_ENV === 'development') {
        console.log('Sending user data to createPortalSession:', {userId, email: userEmail});
      }
      
      const result = await createPortalSessionFn({ 
        userId, 
        userEmail,
        returnUrl: `${window.location.origin}/profile` 
      });
      
      // Type assertion für das Ergebnis
      const data = result.data as { url: string };
      
      if (!data.url) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Portal session creation failed: No URL returned');
        }
        throw new Error('No portal URL returned from function');
      }
      
      return data.url;
    } catch (error: any) {
      // Enhance error debugging by logging more details
      if (process.env.NODE_ENV === 'development') {
        console.error('Error creating portal session:', error);
      }
      
      // Check if it's a FirebaseError with code
      if (error.code) {
        if (process.env.NODE_ENV === 'development') {
          console.error(`Firebase error code: ${error.code}`);
        }
      }
      
      // Check for specific function error message that indicates customer not found
      if (error.message && error.message.includes('Could not find Stripe customer account')) {
        // Log subscription data for debugging
        try {
          const subscriptionData = await this.getSubscription(userId);
          if (process.env.NODE_ENV === 'development') {
            console.log('Current subscription data:', subscriptionData);
          }
        } catch (subError) {
          if (process.env.NODE_ENV === 'development') {
            console.error('Failed to get subscription data for debugging:', subError);
          }
        }
        
        throw new Error('Could not find Stripe customer account. Please contact support.');
      }
      
      // Generic error with more information
      throw error;
    }
  }

  /**
   * Verify a checkout session
   */
  async verifyCheckoutSession(sessionId: string, userId: string): Promise<{ success: boolean }> {
    try {
      // In Entwicklungsumgebung: Firebase callable function verwenden
      if (process.env.NODE_ENV === 'development') {
        console.log('Verifying checkout session using Firebase callable function');
      }
      const verifyCheckoutFn = httpsCallable(functions, 'verifyCheckoutSession');
      const result = await verifyCheckoutFn({ sessionId, userId });
      // Type assertion für das Ergebnis
      return result.data as { success: boolean };
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error verifying checkout session:', error);
      }
      return { success: false };
    }
  }

  /**
   * Get pro plan price ID
   */
  getProPlanPriceId(): string {
    return PRO_PRICE_ID;
  }

  /**
   * Get enterprise plan price ID
   */
  getEnterprisePlanPriceId(): string {
    return ENTERPRISE_PRICE_ID;
  }

  /**
   * Renew a canceled subscription
   */
  async renewSubscription(userId: string): Promise<void> {
    try {
      // Immer loggen, unabhängig von der Umgebung
      console.log('Renewing subscription for user', userId);
      
      // Get current subscription details for debugging
      try {
        const subscriptionData = await this.getSubscription(userId);
        console.log('Current subscription before renewal:', subscriptionData);
      } catch (err) {
        console.error('Could not fetch subscription details:', err);
      }
      
      // Instead of creating a new HTTP endpoint with CORS issues,
      // we'll use the customer portal which already works
      // This redirects the user to the Stripe Customer Portal where they can manage their subscription
      const portalUrl = await this.createPortalSession(userId);
      
      // Explicitly navigate to the portal URL
      window.location.href = portalUrl;
      
    } catch (error) {
      // Immer Fehler loggen, unabhängig von der Umgebung
      console.error('Error renewing subscription:', error);
      throw error;
    }
  }

  /**
   * Check if a user is eligible for a trial
   * Users are eligible if they have never had a paid subscription before
   */
  async hasUserHadProTrial(userId: string): Promise<boolean> {
    try {
      if (!userId) {
        console.error('User ID is required to check trial eligibility');
        return true; // Im Zweifelsfall, annehmen dass bereits Trial genutzt wurde
      }
      
      // Check in Firebase whether user has had a trial
      const hasHadTrial = await firebaseService.hasUserHadProTrial(userId);
      
      if (process.env.NODE_ENV === 'development') {
        console.log(`User ${userId} has had trial: ${hasHadTrial}`);
      }
      
      return hasHadTrial;
    } catch (error) {
      console.error('Error checking trial eligibility:', error);
      return true; // Im Fehlerfall, annehmen dass bereits Trial genutzt wurde
    }
  }
}

// Create and export singleton instance
const stripeService = new StripeService();
export default stripeService;
