import { 
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  writeBatch
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { Trade } from '../types/trade';
import { Account } from '../types/account';
import { Notebook } from '../types/notebook';
import { Note } from '../types/note';

interface UserLevel {
  level: number;
  points: number;
  pointsToNextLevel: number;
  completedChallenges: string[];
}

interface UserData {
  accounts: Account[];
  notebooks: Notebook[];
  selectedAccountId: string | null;
  subscription?: {
    planType: 'basic' | 'pro' | 'enterprise';
    status: 'active' | 'canceled' | 'past_due' | 'unpaid' | 'incomplete';
    currentPeriodEnd: number;
    cancelAtPeriodEnd: boolean;
    stripeCustomerId: string;
    stripeSubscriptionId: string | null;
    updatedAt?: any;
    canceledAt?: any;
    willDowngradeTo?: 'basic' | 'pro' | 'enterprise' | null;
    trialEndDate?: number | null;
  };
  stats: {
    totalTrades: number;
    winningTrades: number;
    losingTrades: number;
    breakEvenTrades: number;
    totalPnl: number;
    winRate: number;
    profitFactor: number;
    averageWinningTrade: number;
    averageLosingTrade: number;
    currentStreak: number;
    currentStreakType: 'win' | 'loss' | 'none';
    bestTrade: number;
    worstTrade: number;
    mostTradedPair: string;
    mostProfitablePair: string;
  };
  settings: {
    currency: string;
    timezone: string;
  };
  userLevel: UserLevel;
  createdAt: string;
  updatedAt: string;
  hadProTrial?: boolean;
}

const DEFAULT_USER_DATA: Omit<UserData, 'createdAt' | 'updatedAt'> = {
  accounts: [] as Account[],
  notebooks: [] as Notebook[],
  selectedAccountId: null as string | null,
  subscription: {
    planType: 'basic',
    status: 'active',
    currentPeriodEnd: 0,
    cancelAtPeriodEnd: false,
    stripeCustomerId: '',
    stripeSubscriptionId: null,
    updatedAt: null
  },
  stats: {
    totalTrades: 0,
    winningTrades: 0,
    losingTrades: 0,
    breakEvenTrades: 0,
    totalPnl: 0,
    winRate: 0,
    profitFactor: 0,
    averageWinningTrade: 0,
    averageLosingTrade: 0,
    currentStreak: 0,
    currentStreakType: 'none',
    bestTrade: 0,
    worstTrade: 0,
    mostTradedPair: '',
    mostProfitablePair: ''
  },
  settings: {
    currency: 'USD',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  userLevel: {
    level: 1,
    points: 0,
    pointsToNextLevel: 100,
    completedChallenges: []
  }
};

const firebaseService = {
  // User Data
  async createUserData(userId: string, data: Partial<Omit<UserData, 'createdAt' | 'updatedAt'>>) {
    try {
      console.log('Creating user data:', userId);
      const userDocRef = doc(db, 'users', userId);
      
      // Create initial user document with all required fields
      await setDoc(userDocRef, {
        ...DEFAULT_USER_DATA,
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      // Create trades subcollection
      const tradesCollectionRef = collection(db, 'users', userId, 'trades');
      await setDoc(doc(tradesCollectionRef, '_init'), {
        initialized: true,
        timestamp: serverTimestamp()
      });

      console.log('User data created successfully');
      return true;
    } catch (error) {
      console.error('Error creating user data:', error);
      return false;
    }
  },

  async getUserData(userId: string) {
    try {
      console.log('Getting user data:', userId);
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        console.log('No user data found, creating initial data');
        await this.createUserData(userId, { accounts: [], selectedAccountId: null });
        return { accounts: [], selectedAccountId: null };
      }
      
      const data = userDoc.data();
      console.log('Retrieved user data:', data);
      return data;
    } catch (error) {
      console.error('Error getting user data:', error);
      throw error; // Propagate error to handle it in the UI
    }
  },

  // Trades
  async createTrade(userId: string, trade: Trade): Promise<string> {
    console.log('Creating trade for user:', userId);
    try {
      // Check for duplicates first
      const tradesRef = collection(db, 'users', userId, 'trades');
      const existingTrades = await getDocs(tradesRef);
      
      // Convert timestamps for comparison
      const tradeTimestamp = typeof trade.timestamp === 'string' 
        ? new Date(trade.timestamp).getTime()
        : (trade.timestamp as any).toDate().getTime();
      
      const tradeClosingTime = typeof trade.closingTime === 'string'
        ? new Date(trade.closingTime).getTime()
        : (trade.closingTime as any).toDate().getTime();

      // Check for duplicates by comparing relevant fields
      const isDuplicate = existingTrades.docs.some(doc => {
        const existingTrade = doc.data();
        
        // Convert existing trade timestamps
        const existingTimestamp = existingTrade.timestamp instanceof Timestamp
          ? existingTrade.timestamp.toDate().getTime()
          : new Date(existingTrade.timestamp).getTime();
        
        const existingClosingTime = existingTrade.closingTime instanceof Timestamp
          ? existingTrade.closingTime.toDate().getTime()
          : new Date(existingTrade.closingTime).getTime();

        // Compare only essential fields for duplicate detection
        return (
          Math.abs(existingTimestamp - tradeTimestamp) < 1000 && // Within 1 second
          Math.abs(existingClosingTime - tradeClosingTime) < 1000 && // Within 1 second
          existingTrade.pair === trade.pair &&
          existingTrade.direction === trade.direction &&
          existingTrade.entryPrice === trade.entryPrice &&
          existingTrade.exitPrice === trade.exitPrice &&
          existingTrade.quantity === trade.quantity &&
          existingTrade.grossPnl === trade.grossPnl &&
          existingTrade.fees === trade.fees &&
          existingTrade.netPnl === trade.netPnl &&
          existingTrade.accountId === trade.accountId
        );
      });

      if (isDuplicate) {
        console.log('Duplicate trade detected - skipping import');
        throw new Error('Duplicate trade detected');
      }

      // Convert timestamp and closingTime to Firestore Timestamp
      let timestamp: Timestamp;
      let closingTime: Timestamp;

      // Convert timestamp
      if (typeof trade.timestamp === 'string') {
        const date = new Date(trade.timestamp);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid timestamp format');
        }
        timestamp = Timestamp.fromDate(date);
      } else if (trade.timestamp && typeof trade.timestamp === 'object' && 'toDate' in trade.timestamp) {
        timestamp = trade.timestamp as Timestamp;
      } else {
        throw new Error('Invalid timestamp format');
      }

      // Convert closingTime
      if (typeof trade.closingTime === 'string') {
        const date = new Date(trade.closingTime);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid closingTime format');
        }
        closingTime = Timestamp.fromDate(date);
      } else if (trade.closingTime && typeof trade.closingTime === 'object' && 'toDate' in trade.closingTime) {
        closingTime = trade.closingTime as Timestamp;
      } else {
        throw new Error('Invalid closingTime format');
      }

      const tradeData = {
        ...trade,
        timestamp,
        closingTime,
        emotion: trade.emotion || 'neutral', // Ensure emotion is always saved
        riskRewardRatio: trade.riskRewardRatio || '', // Ensure riskRewardRatio is always saved
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      // Use the existing tradesRef instead of creating a new one
      const docRef = await addDoc(tradesRef, tradeData);
      console.log('Trade created successfully:', docRef.id);

      // Update user stats
      await this.updateUserStats(userId);

      return docRef.id;
    } catch (error) {
      console.error('Error creating trade:', error);
      throw error;
    }
  },

  async updateTrade(userId: string, tradeId: string, updates: Partial<Trade>) {
    try {
      console.log('Updating trade:', tradeId);
      const tradeRef = doc(db, 'users', userId, 'trades', tradeId);
      
      // Remove undefined fields
      const cleanUpdates = Object.entries(updates).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

      await updateDoc(tradeRef, {
        ...cleanUpdates,
        updatedAt: serverTimestamp()
      });

      // Update user stats
      await this.updateUserStats(userId);
      
      return true;
    } catch (error) {
      console.error('Error updating trade:', error);
      return false;
    }
  },

  async deleteTrade(userId: string, tradeId: string) {
    try {
      console.log('Deleting trade:', tradeId);
      const tradeRef = doc(db, 'users', userId, 'trades', tradeId);
      await deleteDoc(tradeRef);
      
      // Update user stats
      await this.updateUserStats(userId);
      
      return true;
    } catch (error) {
      console.error('Error deleting trade:', error);
      return false;
    }
  },

  async deleteTradesByAccountId(userId: string, accountId: string): Promise<boolean> {
    try {
      console.log(`Batch deleting all trades for account: ${accountId}`);
      
      // Get all trades for this account
      const tradesRef = collection(db, 'users', userId, 'trades');
      const q = query(tradesRef, where("accountId", "==", accountId));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        console.log('No trades found for this account');
        return true;
      }
      
      // Create a batch
      const batch = writeBatch(db);
      
      // Add all trades to batch for deletion
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      
      // Commit the batch
      await batch.commit();
      console.log(`Successfully deleted ${querySnapshot.size} trades in batch`);
      
      // Update user stats once after all deletions
      await this.updateUserStats(userId);
      
      return true;
    } catch (error) {
      console.error('Error batch deleting trades:', error);
      return false;
    }
  },

  async getUserTrades(userId: string): Promise<Trade[]> {
    try {
      console.log('Getting trades for user:', userId);
      const tradesRef = collection(db, 'users', userId, 'trades');
      const querySnapshot = await getDocs(tradesRef);
      
      const trades: Trade[] = [];
      querySnapshot.forEach(doc => {
        if (doc.id === '_init') return; // Skip initialization document
        
        const data = doc.data();
        
        // Convert Firestore Timestamp to string ISO format
        let timestamp: string;
        let closingTime: string;
        
        if (data.timestamp instanceof Timestamp) {
          timestamp = data.timestamp.toDate().toISOString();
        } else {
          timestamp = data.timestamp;
        }
        
        if (data.closingTime instanceof Timestamp) {
          closingTime = data.closingTime.toDate().toISOString();
        } else {
          closingTime = data.closingTime;
        }
        
        trades.push({
          ...data,
          id: doc.id,
          timestamp,
          closingTime
        } as Trade);
      });
      
      console.log(`Retrieved ${trades.length} trades for user`);
      return trades;
    } catch (error) {
      console.error('Error getting user trades:', error);
      throw error;
    }
  },

  async batchImportTrades(userId: string, trades: Trade[], accountId: string, updateBalance: boolean = true, currentBalance: number = 0): Promise<{importedCount: number, duplicateCount: number, totalPnl: number}> {
    try {
      console.log(`Starting batch import of ${trades.length} trades`);
      
      // First check for duplicates by getting existing trades for this account
      const tradesRef = collection(db, 'users', userId, 'trades');
      const q = query(tradesRef, where("accountId", "==", accountId));
      const existingTradesSnapshot = await getDocs(q);
      
      const existingTrades: Record<string, any> = {};
      existingTradesSnapshot.forEach(doc => {
        if (doc.id === '_init') return;
        const data = doc.data();
        
        // Convert timestamps for comparison
        const timeKey = data.timestamp instanceof Timestamp 
          ? data.timestamp.toDate().getTime()
          : new Date(data.timestamp).getTime();
          
        const closeTimeKey = data.closingTime instanceof Timestamp
          ? data.closingTime.toDate().getTime()
          : new Date(data.closingTime).getTime();
          
        // Only use essential fields for duplicate detection
        const key = `${timeKey}_${closeTimeKey}_${data.pair}_${data.direction}_${data.entryPrice}_${data.exitPrice}_${data.quantity}_${data.grossPnl}_${data.fees}_${data.netPnl}`;
        existingTrades[key] = true;
      });

      // Create a batch
      const batch = writeBatch(db);
      
      let importedCount = 0;
      let duplicateCount = 0;
      let totalPnl = 0;
      
      for (const trade of trades) {
        // Convert timestamp and closingTime to compare with existing trades
        const tradeTimestamp = typeof trade.timestamp === 'string' 
          ? new Date(trade.timestamp).getTime()
          : (trade.timestamp as any).toDate().getTime();
        
        const tradeClosingTime = typeof trade.closingTime === 'string'
          ? new Date(trade.closingTime).getTime()
          : (trade.closingTime as any).toDate().getTime();
        
        // Only use essential fields for duplicate detection
        const key = `${tradeTimestamp}_${tradeClosingTime}_${trade.pair}_${trade.direction}_${trade.entryPrice}_${trade.exitPrice}_${trade.quantity}_${trade.grossPnl}_${trade.fees}_${trade.netPnl}`;
        
        // Check if this is a duplicate
        if (existingTrades[key]) {
          duplicateCount++;
          continue;
        }
        
        // Convert dates to Firestore Timestamp
        let timestamp: Timestamp;
        let closingTime: Timestamp;

        // Convert timestamp
        if (typeof trade.timestamp === 'string') {
          const date = new Date(trade.timestamp);
          if (isNaN(date.getTime())) {
            console.error('Invalid timestamp format', trade.timestamp);
            continue;
          }
          timestamp = Timestamp.fromDate(date);
        } else if (trade.timestamp && typeof trade.timestamp === 'object' && 'toDate' in trade.timestamp) {
          timestamp = trade.timestamp as Timestamp;
        } else {
          console.error('Invalid timestamp object', trade.timestamp);
          continue;
        }

        // Convert closingTime
        if (typeof trade.closingTime === 'string') {
          const date = new Date(trade.closingTime);
          if (isNaN(date.getTime())) {
            console.error('Invalid closingTime format', trade.closingTime);
            continue;
          }
          closingTime = Timestamp.fromDate(date);
        } else if (trade.closingTime && typeof trade.closingTime === 'object' && 'toDate' in trade.closingTime) {
          closingTime = trade.closingTime as Timestamp;
        } else {
          console.error('Invalid closingTime object', trade.closingTime);
          continue;
        }
        
        // Create a new doc reference
        const newTradeRef = doc(collection(db, 'users', userId, 'trades'));
        
        // Add to batch
        batch.set(newTradeRef, {
          ...trade,
          id: newTradeRef.id,
          timestamp,
          closingTime,
          createdAt: serverTimestamp()
        });
        
        importedCount++;
        totalPnl += trade.netPnl;
      }
      
      // If we're updating the account balance, add that to the batch operation
      if (updateBalance && importedCount > 0) {
        const newBalance = currentBalance + totalPnl;
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const accounts = userData.accounts || [];
          
          // Find and update the account balance
          const updatedAccounts = accounts.map((acc: any) => 
            acc.id === accountId 
              ? { ...acc, currentBalance: newBalance } 
              : acc
          );
          
          // Add account update to the batch
          batch.update(userDocRef, { 
            accounts: updatedAccounts,
            updatedAt: serverTimestamp()
          });
        }
      }
      
      // Execute the batch if we have items to import
      if (importedCount > 0 || updateBalance) {
        await batch.commit();
        console.log(`Batch import complete: ${importedCount} imported, ${duplicateCount} duplicates`);
        
        // Update user stats once after all imports
        await this.updateUserStats(userId);
      } else {
        console.log('Nothing to import - all items were duplicates or invalid');
      }
      
      return { importedCount, duplicateCount, totalPnl };
    } catch (error) {
      console.error('Error in batch import:', error);
      throw error;
    }
  },

  // User Stats
  async updateUserStats(userId: string) {
    try {
      console.log('Updating user stats');
      const trades = await this.getUserTrades(userId);
      
      // Calculate winning and losing trades
      const winningTrades = trades.filter(t => t.netPnl > 0);
      const losingTrades = trades.filter(t => t.netPnl < 0);
      const breakEvenTrades = trades.filter(t => t.netPnl === 0);

      // Calculate profit factor (total winning / total losing)
      const totalWinning = winningTrades.reduce((sum, t) => sum + t.netPnl, 0);
      const totalLosing = Math.abs(losingTrades.reduce((sum, t) => sum + t.netPnl, 0));
      const profitFactor = totalLosing === 0 ? totalWinning : totalWinning / totalLosing;

      // Calculate average trades
      const averageWinningTrade = winningTrades.length > 0 
        ? totalWinning / winningTrades.length 
        : 0;
      const averageLosingTrade = losingTrades.length > 0 
        ? totalLosing / losingTrades.length * -1  // Make it negative to match the UI
        : 0;

      // Calculate current streak
      let currentStreak = 0;
      let currentStreakType: 'win' | 'loss' | 'none' = 'none';
      
      if (trades.length > 0) {
        const sortedTrades = [...trades].sort((a, b) => 
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
        
        const lastTrade = sortedTrades[0];
        currentStreakType = lastTrade.netPnl > 0 ? 'win' : lastTrade.netPnl < 0 ? 'loss' : 'none';
        
        for (const trade of sortedTrades) {
          if ((trade.netPnl > 0 && currentStreakType === 'win') ||
              (trade.netPnl < 0 && currentStreakType === 'loss')) {
            currentStreak++;
          } else {
            break;
          }
        }
      }

      const stats = {
        totalTrades: trades.length,
        winningTrades: winningTrades.length,
        losingTrades: losingTrades.length,
        breakEvenTrades: breakEvenTrades.length,
        totalPnl: trades.reduce((sum, t) => sum + (t.netPnl || 0), 0),
        winRate: trades.length > 0 
          ? (winningTrades.length / trades.length) * 100 
          : 0,
        profitFactor,
        averageWinningTrade,
        averageLosingTrade,
        currentStreak,
        currentStreakType,
        bestTrade: Math.max(...trades.map(t => t.netPnl), 0),
        worstTrade: Math.min(...trades.map(t => t.netPnl), 0),
        mostTradedPair: calculateMostTradedPair(trades),
        mostProfitablePair: calculateMostProfitablePair(trades)
      };

      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        stats,
        updatedAt: serverTimestamp()
      });

      return stats;
    } catch (error) {
      console.error('Error updating user stats:', error);
      throw error;
    }
  },

  // User Level
  async updateUserLevel(userId: string, userLevel: UserLevel): Promise<boolean> {
    try {
      console.log('Updating user level:', userLevel);
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        'userLevel': userLevel,
        updatedAt: serverTimestamp(),
      });
      console.log('User level updated successfully');
      return true;
    } catch (error) {
      console.error('Error updating user level:', error);
      return false;
    }
  },

  async getUserLevel(userId: string): Promise<UserLevel | null> {
    try {
      console.log('Getting user level for user:', userId);
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        console.log('No user data found');
        return null;
      }
      
      const data = userDoc.data();
      console.log('Retrieved user level:', data.userLevel);
      return data.userLevel || null;
    } catch (error) {
      console.error('Error getting user level:', error);
      return null;
    }
  },

  // Accounts
  async updateUserAccounts(userId: string, accounts: Account[]) {
    try {
      console.log('Updating user accounts');
      const userRef = doc(db, 'users', userId);
      
      // Diese Hilfsfunktion entfernt alle "undefined"-Werte in einem Objekt rekursiv
      const removeUndefined = (obj: any): any => {
        if (obj === null || obj === undefined) return null;
        if (typeof obj !== 'object') return obj;
        
        if (Array.isArray(obj)) {
          return obj.map(item => removeUndefined(item));
        }
        
        const result: any = {};
        for (const key in obj) {
          const value = removeUndefined(obj[key]);
          if (value !== undefined) {
            result[key] = value;
          }
        }
        return result;
      };
      
      // Preserve all account fields while ensuring required fields exist
      const cleanAccounts = accounts.map(account => {
        // Reinige die MT5-Verbindungsdaten, falls vorhanden
        let cleanMt5Connection = null;
        if (account.mt5Connection) {
          cleanMt5Connection = {
            enabled: account.mt5Connection.enabled || false,
            login: account.mt5Connection.login || null,
            password: account.mt5Connection.password || null,
            server: account.mt5Connection.server || null,
            lastSyncTime: account.mt5Connection.lastSyncTime || null
          };
        }
        
        const cleanAccount = {
          ...account, // Preserve all fields including type, broker, initialBalance, and riskSettings
          id: account.id,
          name: account.name || 'Unnamed Account',
          currentBalance: account.currentBalance || 0,
          createdAt: account.createdAt || new Date().toISOString(),
          currency: account.currency || 'USD',
          // Optional fields below - preserve them but don't force defaults
          type: account.type || 'demo', // 'demo' | 'live'
          broker: account.broker || '',
          initialBalance: account.initialBalance || 0,
          riskSettings: account.riskSettings || {
            maxPositionSize: 0,
            maxDailyLoss: 0,
            maxDrawdown: 0
          },
          isFavorite: account.isFavorite ?? false,
          // Verwende die gereinigten MT5-Daten
          mt5Connection: cleanMt5Connection
        };
        
        // Entferne alle verbleibenden undefined-Werte
        return removeUndefined(cleanAccount);
      });

      await updateDoc(userRef, {
        accounts: cleanAccounts,
        updatedAt: serverTimestamp()
      });

      console.log('Successfully updated accounts:', cleanAccounts);
      return true;
    } catch (error) {
      console.error('Error updating user accounts:', error);
      throw error; // Propagate error to handle it in the UI
    }
  },

  async updateSelectedAccount(userId: string, selectedAccountId: string | null) {
    try {
      console.log('Updating selected account');
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        selectedAccountId,
        updatedAt: serverTimestamp()
      });
      console.log('Successfully updated selected account:', selectedAccountId);
      return true;
    } catch (error) {
      console.error('Error updating selected account:', error);
      throw error; // Propagate error to handle it in the UI
    }
  },

  // Subscription
  async updateUserSubscription(userId: string, subscriptionData: UserData['subscription']) {
    try {
      console.log('Updating user subscription:', userId, subscriptionData);
      const userDocRef = doc(db, 'users', userId);
      return updateDoc(userDocRef, {
        subscription: subscriptionData,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating user subscription:', error);
      throw error;
    }
  },

  async getUserSubscription(userId: string): Promise<UserData['subscription'] | null> {
    return this.getUserData(userId)
      .then(userData => userData?.subscription || null)
      .catch(error => {
        console.error('Error getting user subscription:', error);
        return null;
      });
  },

  async hasUserHadProTrial(userId: string): Promise<boolean> {
    try {
      if (!userId) return true; // Sicherheitshalber annehmen, dass bereits Trial genutzt wurde
      
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) return false; // Neuer Benutzer hat noch kein Trial gehabt
      
      const userData = userDoc.data();
      
      // In den Benutzerdaten prüfen, ob bereits ein Trial genutzt wurde
      const hadTrial = userData.hadProTrial === true;
      
      // Wenn der Benutzer jemals ein 'pro' oder 'enterprise' Abo hatte, gilt das auch als genutzt
      const hadPaidSubscription = userData.subscription?.planType === 'pro' || 
                                   userData.subscription?.planType === 'enterprise';
      
      return hadTrial || hadPaidSubscription;
    } catch (error) {
      console.error('Error checking if user had Pro trial:', error);
      return true; // Bei Fehler sicherheitshalber annehmen, dass bereits genutzt
    }
  },

  async markUserStartedProTrial(userId: string): Promise<boolean> {
    try {
      if (!userId) return false;
      
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        hadProTrial: true,
        updatedAt: serverTimestamp()
      });
      
      return true;
    } catch (error) {
      console.error('Error marking user started Pro trial:', error);
      return false;
    }
  },

  // Notebooks
  updateUserNotebooks: async (userId: string, notebooks: Notebook[]) => {
    const userRef = doc(db, 'users', userId);
    const timestamp = new Date().toISOString();
    
    try {
      await updateDoc(userRef, {
        notebooks: notebooks.map(notebook => ({
          ...notebook,
          updatedAt: timestamp
        })),
        updatedAt: timestamp
      });
    } catch (error) {
      console.error('Error updating notebooks:', error);
      throw error;
    }
  },

  getUserNotebooks: async (userId: string): Promise<Notebook[]> => {
    const userRef = doc(db, 'users', userId);
    
    try {
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        return [];
      }
      
      const userData = userDoc.data() as UserData;
      return userData.notebooks || [];
    } catch (error) {
      console.error('Error getting notebooks:', error);
      throw error;
    }
  },

  // Notes
  createNote: async (userId: string, notebookId: string, note: Omit<Note, 'id' | 'createdAt' | 'updatedAt'>) => {
    const userRef = doc(db, 'users', userId);
    const timestamp = new Date().toISOString();
    
    try {
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      
      const userData = userDoc.data() as UserData;
      const notebooks = userData.notebooks || [];
      const notebookIndex = notebooks.findIndex(n => n.id === notebookId);
      
      if (notebookIndex === -1) {
        throw new Error('Notebook not found');
      }
      
      const newNote = {
        ...note,
        id: Math.random().toString(36).substr(2, 9),
        createdAt: timestamp,
        updatedAt: timestamp
      };
      
      notebooks[notebookIndex].notes = [...(notebooks[notebookIndex].notes || []), newNote];
      notebooks[notebookIndex].updatedAt = timestamp;
      
      await updateDoc(userRef, {
        notebooks,
        updatedAt: timestamp
      });
      
      return newNote;
    } catch (error) {
      console.error('Error creating note:', error);
      throw error;
    }
  },

  updateNote: async (userId: string, notebookId: string, noteId: string, updates: Partial<Note>) => {
    const userRef = doc(db, 'users', userId);
    const timestamp = new Date().toISOString();
    
    try {
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      
      const userData = userDoc.data() as UserData;
      const notebooks = userData.notebooks || [];
      const notebookIndex = notebooks.findIndex(n => n.id === notebookId);
      
      if (notebookIndex === -1) {
        throw new Error('Notebook not found');
      }
      
      const noteIndex = notebooks[notebookIndex].notes.findIndex(n => n.id === noteId);
      
      if (noteIndex === -1) {
        throw new Error('Note not found');
      }
      
      notebooks[notebookIndex].notes[noteIndex] = {
        ...notebooks[notebookIndex].notes[noteIndex],
        ...updates,
        updatedAt: timestamp
      };
      
      notebooks[notebookIndex].updatedAt = timestamp;
      
      await updateDoc(userRef, {
        notebooks,
        updatedAt: timestamp
      });
    } catch (error) {
      console.error('Error updating note:', error);
      throw error;
    }
  },

  deleteNote: async (userId: string, notebookId: string, noteId: string) => {
    const userRef = doc(db, 'users', userId);
    const timestamp = new Date().toISOString();
    
    try {
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      
      const userData = userDoc.data() as UserData;
      const notebooks = userData.notebooks || [];
      const notebookIndex = notebooks.findIndex(n => n.id === notebookId);
      
      if (notebookIndex === -1) {
        throw new Error('Notebook not found');
      }
      
      notebooks[notebookIndex].notes = notebooks[notebookIndex].notes.filter(n => n.id !== noteId);
      notebooks[notebookIndex].updatedAt = timestamp;
      
      await updateDoc(userRef, {
        notebooks,
        updatedAt: timestamp
      });
    } catch (error) {
      console.error('Error deleting note:', error);
      throw error;
    }
  },
};

export { firebaseService };

function calculateMostTradedPair(trades: Trade[]): string {
  const pairCounts: Record<string, number> = {};

  for (const trade of trades) {
    const pair = trade.pair;
    if (pairCounts[pair]) {
      pairCounts[pair]++;
    } else {
      pairCounts[pair] = 1;
    }
  }

  const mostTradedPair = Object.keys(pairCounts).reduce((a, b) => pairCounts[a] > pairCounts[b] ? a : b);
  return mostTradedPair;
}

function calculateMostProfitablePair(trades: Trade[]): string {
  const pairProfits: Record<string, number> = {};

  for (const trade of trades) {
    const pair = trade.pair;
    const profit = trade.netPnl;
    if (pairProfits[pair]) {
      pairProfits[pair] += profit;
    } else {
      pairProfits[pair] = profit;
    }
  }

  const mostProfitablePair = Object.keys(pairProfits).reduce((a, b) => pairProfits[a] > pairProfits[b] ? a : b);
  return mostProfitablePair;
}
